<template>
    <div>
        <v-container>
            <v-row>
                <v-col>
                    <s-crud
                        title="Componentes"
                        :config="this.configComponent"
                        @save="saveComponent($event)"
                        @clearForm="clearFormComponent()"
                        add
                        edit
                        restore
                        @rowSelected="rowSelectedComponent($event)" 
                        remove
                        :filter="filterComponent"
                    >
                        <template scope="props">
                            <v-container style="padding-top:0px;" v-if="props.item != null" class="pb-0">
                                <v-row>
                                <v-col cols="10" class="s-col-form">
                                    <s-text autofocus ref="DedDescription" v-model="props.item.DedDescription" label="Descripción" />
                                </v-col>
                                <v-col cols="2" class="s-col-form">
                                    <s-text :disabled="props.item.DedID == 0" v-model="props.item.DedValue" label="Valor" />
                                </v-col>
                                <v-col cols="6" class="s-col-form">
                                    <s-text v-model="props.item.DedAbbreviation" label="Abreviatura" />
                                </v-col>
                                <v-col cols="6" class="s-col-form">
                                    <s-text v-model="props.item.DedHelper" label="Utilitario" />
                                </v-col>
                                </v-row>
                            </v-container>
                        </template>
                        <template v-slot:SecStatus="{ row }">
                            <v-chip style="margin:0px" x-small :color="row.SecStatus == 1 ? 'success' : 'error'">
                                {{ row.SecStatus == 1 ? "Activo" : "Inactivo" }}
                            </v-chip>
                        </template> 
                    </s-crud>
                </v-col>
                <v-col>
                    <v-container>
                        <s-select-definition
                            label="Marcas"
                            :def="1164"
                            @change="getListBrand($event)"
                            add
                        ></s-select-definition>
                    </v-container>
                    <v-data-table
                        :items="listBrands"
                        :headers="configBrand.headers"
                        show-select
                        item-key="DedValue"
                        v-model="listBrandsSelected"
                        @item-selected="clickItemSelectBrand($event)"
                    >

                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>

        <!--  <s-crud
            title="Marca Componentes"
            :config="this.config"
            add
            edit
            remove
            @save="save($event)"
            search
        >
            <template scope="props">
                <v-container>
                    <v-row>
                        <v-col>
                            <s-text
                                label="Nombre"
                                v-model="props.item.TbcName"
                            ></s-text>
                        </v-col>
                        <v-col>
                            <s-select-definition
                                label="Tipo Componente"
                                :def="1311"
                                v-model="props.item.TypeComponent"
                            ></s-select-definition>
                        </v-col>
                        <v-col>
                            <s-select-definition
                                label="Estado"
                                :def="1179"
                                v-model="props.item.TbcStatus"
                            ></s-select-definition>
                        </v-col>
                    </v-row>
                </v-container>
            </template>

            <template v-slot:TbcStatus="{ row }">
                <v-chip x-small :color="row.TbcStatusColor">
                    {{  row.TbcStatusName }}
                </v-chip>
            </template>
        </s-crud> -->
    </div>
</template>

<script>
    import _service from "@/services/InformationTechnology/BrandComponentService";
    import _sDefinitionDetail from "@/services/General/DefinitionDetailService";

    export default {
        data() {
            return {
                filterComponent : {
                    DefID: 1311, 
                    SecStatus: 0 
                },
                filterBrand: {
                    DefID: 1164, 
                    SecStatus: 0 
                },
                listBrands: [],
                listBrandsSelected : [],
                listBrandsSelectedBackup : [],
                componentSelected : {},
                itemBrandComponent: {},
                config: {
                    model : {
                        TbcID: "ID",
                        TbcStatus: 0,
                        TypeComponentName: "string",
                        TbcName: "string"
                    },
                    headers: [
                        { text : "ID" , value : "TbcID" },
                        { text : "Marca" , value : "TbcName" },
                        { text : "Tipo Componente" , value : "TypeComponentName" },
                        { text : "Estado" , value : "TbcStatus" },
                    ],
                    service : _service
                },
                configComponent: {
                    service: _sDefinitionDetail,
                    model: {
                        
                        DedID: "ID",
                        DefID: "int",
                        DedDescription: "string",
                        DedAbbreviation: "string",
                        DedHelper: "string",
                        DedValue: "int",
                        SecStatus: "status",
                    },
                    headers: [
                        { text: "ID", value: "DedID", width: "5%", align: "end", sorteable: true },
                        { text: "Definicion", value: "DedDescription", width: "55%", sorteable: true },
                        { text: "Abreviatura", value: "DedAbbreviation", width: "15%", sorteable: false },
                        { text: "Helper",  value: "DedHelper", width: "15%", sorteable: false },
                        { text: "Valor", value: "DedValue", width: "5%", align: "center", sorteable: true, },
                        { text: "Estado", value: "SecStatus", width: "5%", align: "center", sorteable: false,
                        },
                    ],
                },
                configBrand: {
                    service: _sDefinitionDetail,
                    model: {
                        
                        DedID: "ID",
                        DefID: "int",
                        DedDescription: "string",
                        DedAbbreviation: "string",
                        DedHelper: "string",
                        DedValue: "int",
                        SecStatus: "status",
                    },
                    headers: [
                        { text: "ID", value: "DedID", width: "5%", align: "end", sorteable: true },
                        { text: "Definicion", value: "DedDescription", width: "55%", sorteable: true },
                        { text: "Abreviatura", value: "DedAbbreviation", width: "15%", sorteable: false },
                        { text: "Helper",  value: "DedHelper", width: "15%", sorteable: false },
                        { text: "Valor", value: "DedValue", width: "5%", align: "center", sorteable: true, },
                        { text: "Estado", value: "SecStatus", width: "5%", align: "center", sorteable: false,
                        },
                    ],
                },
            }
        },

        methods: {
            save(item) {
                item.save();
            },

            saveComponent(item) {
                item.DefID = this.filterComponent.DefID;
                    item.UsrCreateID = this.$fun.getUserID();
                    if (item.DedDescription.length == 0) {
                    this.$fun.alert("Registre descripción de valor", "warning");
                    return;
                }
                item.save();       
            },

            saveBrand(item) {
                item.DefID = this.filterBrand.DefID;
                    item.UsrCreateID = this.$fun.getUserID();
                    if (item.DedDescription.length == 0) {
                    this.$fun.alert("Registre descripción de valor", "warning");
                    return;
                }
                item.save();       
            },

            clearFormComponent() {
                this.$refs.DedDescription.focus();
            },

            rowSelectedComponent(items) {
                if(items.length > 0)
                {
                    this.componentSelected = items[0];
                    this.componentSelected.TypeComponent = this.componentSelected.DedValue;
                    this.componentSelected.Search = '';
                    _service
                    .list(this.componentSelected, this.$fun.getUserID())
                    .then(resp => {
                        if(resp.status == 200)
                        {
                            this.listBrandsSelected = resp.data;
                            this.listBrandsSelectedBackup = resp.data;
                            this.listBrandsSelected.map(element =>{
                                element.DedValue = element.TypeBrand
                            });
                        }
                    })
                }
            },

            getListBrand(brands)
            {
                this.listBrands = brands;
            },

            clickItemSelectBrand(item)
            {

                this.itemBrandComponent = {
                    TypeComponent : this.componentSelected.DedValue,
                    TypeBrand : item.item.DedValue,
                    TbcStatus : 1,
                    SecStatus : 1,
                    UsrCreateID : this.$fun.getUserID(),
                    UsrUpdateID : this.$fun.getUserID(),
                }
                
                _service
                .save(this.itemBrandComponent, this.$fun.getUserID())
                .then(resp => {
                    if(resp.status == 200)
                    {
                        this.$fun.alert("Actualizado correctamente", "success");
                    }
                })
            }
        },
    }
</script>
